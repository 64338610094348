// Make an element is clickable
.itr-clickable {
  cursor: pointer;
}

// Set loading background
.itr-loading {
  background-image: url('~assets/img/loader_button.gif');
}

.my-top-center {
  top: 2%;
  right: 35%;
  width: auto;
}

.custom-modal-box {
  padding: 0;
  mat-dialog-container {
    padding: 0;
  }
}

.mat-expansion-indicator::after {
  //border-color: black;
  @apply border-gray;
}
