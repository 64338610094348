.default-cell-setup {
  @apply flex items-center font-bold text-sm;
}

.template {
  color: #DA950B;
}

.formalized {
  color: #64B621;
}

.new {
  color: #8F99A3;
}

.assigned {
  color: #8F99A3;
}

.running {
  color: #C1A30B;
}

.completed {
  color: #64B621;
}

.approved {
  color: #64B621;
}

.expired {
  color: #D74242;
}
//"new" | "started" | "canceled" | "finished";

.started {
  color: #64B621;
}

.canceled {
  color: #D74242;
}

.finished {
  color: #8F99A3;
}
