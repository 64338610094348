.media-wrapper {
    // width: 870px;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 17px;
    position: relative;
    border-top: 10px solid #000;
    display: inline-flex;
}

.video-small {
    position: absolute;
    top: 0; left: 10px;
    border: 3px solid #fff;

    .close,
    .clean {
        width: 40px;
        height: 40px;
        background-color: rgba(#df242b, .8);
        position: absolute;
        top: 0; right: 0;
        display: flex;
        cursor: pointer;

        &:hover {
            opacity: .9;

            img {
                transform: scale(.9);
            }
        }

        img {
             margin: auto;
             transition: all .2s ease-out;
          }
    }

    .close:hover {
        opacity: .9;

        img {
            transform: rotate(90deg) scale(.9);
        }
    }

    .clean {
        top: auto;
        bottom: 0;
    }
}

.controls {
    width: 100%;
    position: absolute;
    bottom: 15px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .2s ease-out;
    padding-left: 1px;
    padding-right: 20px;
    @media (max-width: 1299px) {
        bottom: 5%;
    }

    .exit {
        margin-right: 25px;
        &:hover {
            opacity: .9;
        }
    }
}

.media-controls a {
    width: 65px;
    height: 65px;
    background-color: rgba(#191919, .8);
    display: inline-flex;
    transition: all .2s ease-out;

    img {
        margin: auto;
    }

    &:hover {
        opacity: .8;
    }
}

.sessionsWrapper .aside {
    margin-top: -40px;
}

.aside {
    // flex-grow: 1;
    padding-left: 30px;
    width: 320px;

    .buttons-container { margin-left: auto }

    &-row {
        padding: 15px 0;
        position: relative;

        &:not(:last-of-type) {
            border-bottom: 1px solid #cbcbcb;
        }
    }

    &__block {
        margin-bottom: 30px;
    }

    &__title {
        font-size: 16px;
        color: #424242;
        font-weight: 500;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &-tabs {
        display: flex;
        padding: 10px 0 5px;

        & > * {
            flex-grow: 1;
            text-align: center;
        }
    }

    &-tab {
        display: none;
        &.active {
            display: block;
            animation: fadeIn .5s linear forwards;
        }
    }

    &-link {
        position: relative;

        &:first-of-type {
            i {
                font-size: 34px;
                margin-top: 3px;
            }
        }

        i {
            font-size: 40px;
            text-decoration: none;
            display: inline-block;
            color: #5B5B5BFF;
            transition: all .2s ease-out;
            user-select: none;
        }

        &:before {
            content: '';
            width: 60px;
            height: 60px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background-color: var(--primary-color);
            opacity: 0;
            border: 1px solid rgba(#000, .15);
        }

        &.active {

            &:before {
                animation: pulse .5s linear forwards;
            }

            &:after {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 7.5px 8px 7.5px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: -21px;
                left: 50%;
                transform: translateX(-50%);
                filter: drop-shadow(0 -1.5px 0 #bfbfbf);
            }

            i {
                color: var(--primary-color);
            }

        }
    }
    .upload {
        // position: relative;
        cursor: pointer;
        color: #424242;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 25px;
        margin-top: 10px;
        text-transform: uppercase;
        border-bottom: 1px dashed #424242;

        input {
            position: absolute;
            opacity: 0;
            // visibility: hidden;
            // display: none;
        }

        // span {
        //     position: relative;
        //     z-index: 5;
        //     cursor: pointer;
        // }
    }
}


.tech-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5B5B5BFF;
    font-size: 14px;
    font-weight: 400;

    .counter {
        font-size: 20px;
        font-weight: 700;
    }
}

.chat {

    &-body {
        min-height: 50px;
        max-height: 115px;
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: contain;
        margin-bottom: 15px;
        border-bottom: 1px solid #cbcbcb;
    }

    &-item {
        font-size: 14px;
        color: #5B5B5BFF;

        time {
            color: #cbcbcb;
        }
        p {
            line-height: 20px;
            font-weight: 500;
            text-shadow: 0 0 0 currentColor;
        }

        & + * {
            margin-top: 15px;
        }
    }

    &-footer {
        display: flex;
        align-items: center;
        input {
            flex-grow: 1;
        }
    }
}

.input {
    appearance: none;
    border: 0;
    color: #5B5B5BFF;

    //@include placeholder {
    //    color:#cbcbcb; opacity:1
    //}
}

.msg-btn {
    display: inline-block;
    appearance: none;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    outline: 0 !important;
}

.color {

    &-row {
        display: flex;
        &:first-of-type {
            margin-top: 25px;
        }
    }

    &-row + &-row {
        margin-top: 20px;
    }

    &-item {
        color: #cbcbcb;
        font-size: 30px;
        width: 1em;
        height: 1em;
        line-height: 1em;
        text-align: center;
        border: 1px solid currentColor;
        outline-offset: 1px;
        margin-left: em(-1px, 28px);
        background-color: #fff;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        transition: all .2s ease-out;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 1px;
        //     right: 1px;
        //     bottom: 1px;
        //     left: 1px;
        //     border: 1px dashed currentColor;
        //     margin: auto;
        //     display: none;
        // }

        &:not(.active):hover {
            color: #777;
            z-index: 5;
            //box-shadow: 0 2px 8px rgba(#000, .2);
        }

        &.active {
            color: #000;
            z-index: 5;
            &:after {
                display: block;
                border-color: currentColor
            }
        }

        i {
            font-size: em(26px, 30px);
        }
        &--color {
            border-color: #fff;
        }
    }
}

.call-popup {
    &__button {
        max-width: 170px;
        width: 100%;
        height: 50px;
        display: flex !important;
        align-items: center;
        font-size: 17px !important;
        i {
            font-size: 30px;
            margin-right: 10px;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes pulse {
    0% {
        opacity: .1;
        transform: scale(.2);
    }

    30% {
        transform: scale(.8);
        opacity: .25;
    }

    100% {
        opacity: 0;
        transform: scale(1.2);
    }
}
