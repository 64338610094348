
.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $color-footer-bg;
    padding: 24px 0 19px;

    @media only screen and (max-width: 1600px) {
        // left: 216px;
    }

    @media only screen and (max-width: 1367px) {
        min-width: 100% !important;
    }

    .row {
        justify-content: space-between;
        align-items: center;
    }
    a {
        color: #ffffff;
        font-size: 13px;
        line-height: 1;
        margin: 0;
    }
}

.copyright {
    color: #f2f5f1;
    font-size: 12px;
    line-height: 1;
    margin: 0;
}

.address {
    color: #ffffff;
    font-size: 13px;
    line-height: 1;
    margin: 0;
}
