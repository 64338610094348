$color-active: var(--primary-color);
$color-no-active: #879d7f;
$color-active-opacity: #aad98f;
$color-light: #c9d9bf;
$color-ligth: #c9d9bf;
$color-ligth2: #f2f5f1;
$color-dark: #9fb199;
$color-hover-table: #8ebb53;
$color-hover-table-main-text: #272727;
$color-black: #424242;
$color-black2: #212529;
$color-grayBlack: #5b5b5b;
$color-lightBlack: #666666;
$color-lightBlack-active: #515151;
$color-lightGreen: rgba(113, 191, 68, 0.74);
$color-warn: #ff0301;
$color-shadow: rgba(108, 108, 108, 0.43);
$color-white: #ffffff;
$color-footer-bg: #5b5b5b;
$color-light-grey-bg: #f4f4f4;
$green: #68af40;
$gray: #b8b8b8;


//цвета для левого меню
$color-left-bar-wrap: #5b5b5b;
$color-left-bar-logo: #515151;
$color-left-bar-active: #71bf44;
$color-left-bar-light-active: #515151;

// material forms design
$color-material-btn-hover: rgba(0,0,0,.03);

// borders
$color-border-black: #424242;

// tables
$ag-row-color: #f5f9f0;
$ag-head-bg: #F5F7F6;

// color for elements' label
$color-label: #b4b4b4;