.backlink {
  @apply flex items-center mb-4;

  &__icon {
    @apply text-base mr-1.5;
  }

  &__link {
    @apply flex items-center text-gray-darker text-xs hover:no-underline;

    &:hover {
      .backlink {
        &__text {
          @apply no-underline;
        }
      }
    }
  }

  &__text {
    @apply  underline text-gray-darker capitalize-first;
  }
}
