.expertWrapper {
    position: relative;
    // padding-top: 75px;
    margin-bottom: 40px;
    overflow: initial;
    .container {
        border-bottom: 1px solid #cbcbcb;
    }
    form {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        top: 0;
        left: 0;
        > div:first-child {
            margin-top: 0 !important;
        }
        &.active {
            position: relative;
            opacity: 1;
            visibility: visible;
        }
        &.connections {
            margin-bottom: 170px;
        }

        button {
            margin: 42px 0 40px 201px;
        }
    }
    .connections {
        margin-bottom: 80px;
    }
    &.inactive {
        .info {
            .status {
                vertical-align: top;
                margin-top: 21px;
                p {
                    color: #e13339;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
        form {
            .field {
                input[type=email] {
                    color: #e13339;
                    border-bottom-color: #e13339;
                }
            }
        }
    }
    &.removed {
        margin-bottom: 50px;
        .restore {
            display: inline-block;
            vertical-align: top;
            color: var(--primary-color);
            font-size: 15px;
            text-transform: uppercase;
            border-bottom: 1px solid var(--primary-color);
            line-height: 1.2;
            margin: 18px 0 0 94px;
            text-decoration: none;
        }
        .avatarBlock {
            .avatar {
                background-color: rgba(225, 51, 57, 0.74);
            }
        }
        .status {
            .removedDate {
                color: #e13339;
            }
        }
        form {
            padding-bottom: 70px;
            .names {
                margin: -2px 0 0 56px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
            .field {
                margin-top: 55px;
                input {
                    border: none;
                    background: transparent;
                }
                label {
                    margin-top: 6px;
                }
            }
            .roles {
                .list {
                    margin-top: -25px;
                }
                .line {
                    margin-top: 0;
                }
                input[type=checkbox] ~ label {
                    display: none;
                    cursor: default;
                }
                input[type=checkbox]:checked ~ label {
                    display: block;
                    margin-top: 25px;
                    &:before {
                        border-color: transparent;
                    }
                }
            }
            .selectBox {
                margin-top: 60px;
            }
        }
    }
}


