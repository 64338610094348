
/* Redefine angular material defaults*/
//@import "apps/itorum-frontend/src/styles/angular_material.scss";

//@import "assets/css/libs.css";
@import "app/variable/variable";
@import "styles/_angular-material.scss";
//@import "node_modules/bootstrap/scss/bootstrap";

//body{
//    color: #4242422 !important;
//}

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "styles/_common.scss";
@import "styles/partials/_page";
@import "styles/_fonts.scss";
@import 'styles/_buttons.scss';

@import "styles/partials";
@import 'styles/_btn.scss';
@import 'styles/_main.scss';
@import 'styles/_techcards.scss';
@import 'styles/_page.scss';
@import 'styles/_responsive.scss';
@import 'styles/_side-modal.component.scss';

//@import './app/shared/shared';

@import "styles/_utils";

@import "~@ag-grid-community/core/dist/styles/ag-grid.css";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham.css";
@import "styles/partials/_ag-table-header";
@import '~ngx-toastr/toastr';

.my-button {
  @apply bg-green w-full;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~angular-calendar/css/angular-calendar.css";
