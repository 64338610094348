@font-face {
  font-family: 'icomoon';
  src:  url('~assets/fonts/icomoon.eot?9u9yoy');
  src:  url('~assets/fonts/icomoon.eot?9u9yoy#iefix') format('embedded-opentype'),
  url('~assets/fonts/icomoon.ttf?9u9yoy') format('truetype'),
  url('~assets/fonts/icomoon.woff?9u9yoy') format('woff'),
  url('~assets/fonts/icomoon.svg?9u9yoy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tf_add_vector:before {
  content: "\e97f";
}
.icon-mini_pause:before {
  content: "\e962";
}
.icon-mini_pay:before {
  content: "\e963";
}
.icon-pause1 .path1:before {
  content: "\e964";
  color: rgb(100, 182, 33);
}
.icon-pause1 .path2:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-play .path1:before {
  content: "\e978";
  color: rgb(100, 182, 33);
}
.icon-play .path2:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-chat_send:before {
  content: "\e97e";
}
.icon-loop:before {
  content: "\e903";
}
.icon-stat:before {
  content: "\e900";
}
.icon-zoom:before {
  content: "\e901";
}
.icon-uniE901:before {
  content: "\e902";
}
.icon-arrow:before {
  content: "\e905";
}
.icon-back:before {
  content: "\e906";
}
.icon-basket:before {
  content: "\e907";
}
.icon-call:before {
  content: "\e908";
}
.icon-camera:before {
  content: "\e909";
}
.icon-circle:before {
  content: "\e90a";
}
.icon-copy:before {
  content: "\e90b";
}
.icon-down:before {
  content: "\e90c";
}
.icon-download:before {
  content: "\e90d";
}
.icon-draw:before {
  content: "\e90e";
}
.icon-exit:before {
  content: "\e90f";
}
.icon-fullscreen:before {
  content: "\e910";
}
.icon-fullscreen-close:before {
  content: "\e911";
}
.icon-gallery:before {
  content: "\e912";
}
.icon-home:before {
  content: "\e913";
}
.icon-if_table:before {
  content: "\e914";
}
.icon-line:before {
  content: "\e915";
}
.icon-line-1:before {
  content: "\e916";
}
.icon-link1:before {
  content: "\e917";
}
.icon-list:before {
  content: "\e918";
}
.icon-message:before {
  content: "\e919";
}
.icon-micro:before {
  content: "\e91a";
}
.icon-micro-off:before {
  content: "\e91b";
}
.icon-new:before {
  content: "\e91c";
}
.icon-new-group:before {
  content: "\e91d";
}
.icon-picture:before {
  content: "\e91e";
}
.icon-search:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e920";
}
.icon-sound:before {
  content: "\e921";
}
.icon-sound-off:before {
  content: "\e922";
}
.icon-square:before {
  content: "\e923";
}
.icon-thickness:before {
  content: "\e924";
}
.icon-thickness-2:before {
  content: "\e925";
}
.icon-thickness-3:before {
  content: "\e926";
}
.icon-up:before {
  content: "\e927";
}
.icon-v:before {
  content: "\e928";
}
.icon-video:before {
  content: "\e929";
}
.icon-x:before {
  content: "\e92a";
}
.icon-call-end:before {
  content: "\e92b";
}
.icon-call-new:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e92d";
}
.icon-filter:before {
  content: "\e92e";
}
.icon-tune_black:before {
  content: "\e9e6";
}
.icon-open_in_new:before {
  content: "\e9e4";
}
.icon-print:before {
  content: "\e9e5";
}
.icon-pie_chart:before {
  content: "\e9c3";
}
.icon-line_chart:before {
  content: "\e9e3";
}
.icon-expand_alreday .path1:before {
  content: "\e980";
}
.icon-expand_alreday .path2:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-expand_more_circle_list:before {
  content: "\e9c1";
}
.icon-add_plus_circle_list:before {
  content: "\e9c4";
}
.icon-tf_add_photo_alternate:before {
  content: "\e982";
}
.icon-tf_article_black:before {
  content: "\e983";
}
.icon-tf_big_plus:before {
  content: "\e984";
}
.icon-tf_call_split:before {
  content: "\e985";
}
.icon-tf_content_copy:before {
  content: "\e990";
}
.icon-tf_delete:before {
  content: "\e991";
}
.icon-tf_drag_indicator:before {
  content: "\e995";
}
.icon-tf_edit:before {
  content: "\e996";
}
.icon-tf_help_outline:before {
  content: "\e997";
}
.icon-tf_image:before {
  content: "\e998";
}
.icon-tf_map:before {
  content: "\e999";
}
.icon-tf_more_horiz:before {
  content: "\e99a";
}
.icon-tf_photo_camera:before {
  content: "\e99e";
}
.icon-tf_play:before {
  content: "\e99f";
}
.icon-tf_plus:before {
  content: "\e9a0";
}
.icon-tf_poll:before {
  content: "\e9a1";
}
.icon-tf_remove:before {
  content: "\e9a2";
}
.icon-tf_report_problem:before {
  content: "\e9a3";
}
.icon-tf_start:before {
  content: "\e9a4";
}
.icon-tf_timer:before {
  content: "\e9a5";
}
.icon-tf_upload_file:before {
  content: "\e9a6";
}
.icon-tf_vertical_align_bottom:before {
  content: "\e9a7";
}
.icon-tf_videocam:before {
  content: "\e9a8";
}
.icon-colordot_black:before {
  content: "\e938";
}
.icon-tool_hand_black:before {
  content: "\e939";
}
.icon-downloading_black:before {
  content: "\e93a";
}
.icon-tool_draw_black:before {
  content: "\e93b";
}
.icon-account_circle_black:before {
  content: "\e93c";
}
.icon-rotate_right_black:before {
  content: "\e93d";
}
.icon-redo_black:before {
  content: "\e93e";
}
.icon-undo_black:before {
  content: "\e93f";
}
.icon-done_black:before {
  content: "\e940";
}
.icon-tool_line_black:before {
  content: "\e941";
}
.icon-expand_more_black:before {
  content: "\e944";
}
.icon-mic_off_black:before {
  content: "\e945";
}
.icon-personal_video_black:before {
  content: "\e947";
}
.icon-close_fullscreen_black:before {
  content: "\e92f";
}
.icon-call_end_black:before {
  content: "\e930";
}
.icon-mic_none_black:before {
  content: "\e931";
}
.icon-open_in_full:before {
  content: "\e932";
}
.icon-people_black_24dp:before {
  content: "\e933";
}
.icon-pan_tool:before {
  content: "\e904";
}
.icon-filter_alt_black:before {
  content: "\e948";
}
.icon-tool_circle_black:before {
  content: "\e949";
}
.icon-smartphone_black:before {
  content: "\e94a";
}
.icon-ondemand_video_black:before {
  content: "\e94b";
}
.icon-trash_can_black:before {
  content: "\e94c";
}
.icon-cross_clear_black:before {
  content: "\e94d";
}
.icon-tool_square_black:before {
  content: "\e94e";
}
.icon-plus_black:before {
  content: "\e94f";
}
.icon-line_weight_black:before {
  content: "\e950";
}
.icon-tool_arrow_black:before {
  content: "\e951";
}
.icon-scale_increase_black:before {
  content: "\e952";
}
.icon-scale_decrease_black:before {
  content: "\e953";
}
.icon-expand_more_black_right:before {
  content: "\e954";
}
.icon-expand_more_black_left:before {
  content: "\e955";
}
.icon-expand_more_black_up:before {
  content: "\e95c";
}
.icon-expand_more_black_down:before {
  content: "\e95d";
}
.icon-photo_camera_black:before {
  content: "\e95e";
}
.icon-description_black:before {
  content: "\e95f";
}
.icon-person_add_alt_1_black:before {
  content: "\e960";
}
.icon-present_to_all_black:before {
  content: "\e961";
}
.icon-question_answer:before {
  content: "\e934";
}
.icon-broadcasting:before {
  content: "\e935";
}
.icon-pencil:before {
  content: "\e9c5";
}
.icon-pencil2:before {
  content: "\e9c6";
}
.icon-quill:before {
  content: "\e9c7";
}
.icon-pen:before {
  content: "\e9c8";
}
.icon-eyedropper:before {
  content: "\e9c9";
}
.icon-headphones:before {
  content: "\e936";
}
.icon-mic:before {
  content: "\e937";
}
.icon-file-empty:before {
  content: "\e9a9";
}
.icon-files-empty:before {
  content: "\e9aa";
}
.icon-file-text2:before {
  content: "\e9ab";
}
.icon-file-picture:before {
  content: "\e9ac";
}
.icon-file-music:before {
  content: "\e9ad";
}
.icon-file-play:before {
  content: "\e9ae";
}
.icon-file-video:before {
  content: "\e9af";
}
.icon-file-zip:before {
  content: "\e9b0";
}
.icon-copy1:before {
  content: "\e9b1";
}
.icon-paste:before {
  content: "\e9b2";
}
.icon-stack:before {
  content: "\e9b3";
}
.icon-barcode:before {
  content: "\e9b4";
}
.icon-qrcode:before {
  content: "\e9b5";
}
.icon-ticket:before {
  content: "\e9b6";
}
.icon-phone:before {
  content: "\e942";
}
.icon-phone-hang-up:before {
  content: "\e943";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-map:before {
  content: "\e9b7";
}
.icon-map2:before {
  content: "\e9b8";
}
.icon-display:before {
  content: "\e956";
}
.icon-laptop:before {
  content: "\e957";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-mobile2:before {
  content: "\e959";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-tv:before {
  content: "\e95b";
}
.icon-download1:before {
  content: "\e9ca";
}
.icon-upload:before {
  content: "\e9cc";
}
.icon-undo:before {
  content: "\e965";
}
.icon-redo:before {
  content: "\e966";
}
.icon-undo2:before {
  content: "\e967";
}
.icon-redo2:before {
  content: "\e968";
}
.icon-forward:before {
  content: "\e969";
}
.icon-reply:before {
  content: "\e96a";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-bubbles2:before {
  content: "\e96d";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-bubbles3:before {
  content: "\e96f";
}
.icon-bubbles4:before {
  content: "\e970";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-user-check:before {
  content: "\e975";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner3:before {
  content: "\e97c";
}
.icon-spinner4:before {
  content: "\e97d";
}
.icon-spinner7:before {
  content: "\e9b9";
}
.icon-spinner8:before {
  content: "\e9ba";
}
.icon-spinner9:before {
  content: "\e9bb";
}
.icon-spinner10:before {
  content: "\e9bc";
}
.icon-spinner11:before {
  content: "\e9be";
}
.icon-search1:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-shrink:before {
  content: "\e98a";
}
.icon-enlarge2:before {
  content: "\e98b";
}
.icon-shrink2:before {
  content: "\e98c";
}
.icon-key:before {
  content: "\e98d";
}
.icon-key2:before {
  content: "\e98e";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e9cd";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-equalizer2:before {
  content: "\e993";
}
.icon-cog:before {
  content: "\e994";
}
.icon-pie-chart:before {
  content: "\e9c2";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-stats-bars2:before {
  content: "\e99d";
}
.icon-bin:before {
  content: "\e9bf";
}
.icon-bin2:before {
  content: "\e9c0";
}
.icon-target:before {
  content: "\e9ce";
}
.icon-power:before {
  content: "\e9cf";
}
.icon-switch:before {
  content: "\e9d0";
}
.icon-power-cord:before {
  content: "\e9d1";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-cloud:before {
  content: "\e9d2";
}
.icon-cloud-download:before {
  content: "\e9d3";
}
.icon-cloud-upload:before {
  content: "\e9d4";
}
.icon-cloud-check:before {
  content: "\e9d5";
}
.icon-download2:before {
  content: "\e9d6";
}
.icon-upload2:before {
  content: "\e9d7";
}
.icon-download3:before {
  content: "\e9d8";
}
.icon-upload3:before {
  content: "\e9d9";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-attachment:before {
  content: "\e9da";
}
.icon-eye:before {
  content: "\e9db";
}
.icon-eye-plus:before {
  content: "\e9dc";
}
.icon-eye-minus:before {
  content: "\e9dd";
}
.icon-eye-blocked:before {
  content: "\e9de";
}
.icon-bookmark:before {
  content: "\e9df";
}
.icon-bookmarks:before {
  content: "\e9e0";
}
.icon-star-half:before {
  content: "\e9e1";
}
.icon-star-full:before {
  content: "\e9e2";
}
.icon-question:before {
  content: "\ea09";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit1:before {
  content: "\ea14";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-stop:before {
  content: "\ea17";
}
.icon-previous:before {
  content: "\ea18";
}
.icon-next:before {
  content: "\ea19";
}
.icon-backward:before {
  content: "\ea1a";
}
.icon-forward2:before {
  content: "\ea1b";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-stop2:before {
  content: "\ea1e";
}
.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-first:before {
  content: "\ea21";
}
.icon-last:before {
  content: "\ea22";
}
.icon-previous2:before {
  content: "\ea23";
}
.icon-next2:before {
  content: "\ea24";
}
.icon-eject:before {
  content: "\ea25";
}
.icon-volume-high:before {
  content: "\ea26";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-low:before {
  content: "\ea28";
}
.icon-volume-mute:before {
  content: "\ea29";
}
.icon-volume-mute2:before {
  content: "\ea2a";
}
.icon-volume-increase:before {
  content: "\ea2b";
}
.icon-volume-decrease:before {
  content: "\ea2c";
}
.icon-loop1:before {
  content: "\ea2d";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-infinite:before {
  content: "\ea2f";
}
.icon-shuffle:before {
  content: "\ea30";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down-right:before {
  content: "\ea35";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-down-left:before {
  content: "\ea37";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up-left2:before {
  content: "\ea39";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-down-left2:before {
  content: "\ea3f";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-tux:before {
  content: "\eabd";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-finder:before {
  content: "\eabf";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows:before {
  content: "\eac1";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-chrome:before {
  content: "\ead9";
}
.icon-firefox:before {
  content: "\eada";
}
.icon-IE:before {
  content: "\eadb";
}
.icon-edge:before {
  content: "\eadc";
}
.icon-safari:before {
  content: "\eadd";
}
.icon-opera:before {
  content: "\eade";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-libreoffice:before {
  content: "\eae3";
}
