/* /Main */
.notification {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 15px;
    min-width: 1200px;
    max-width: 1370px;
    font-size: 0;
    img {
        display: inline-block;
        vertical-align: middle;
    }
    .text {
        display: inline-block;
        vertical-align: middle;
        color: #ffffff;
        font-size: 14px;
        line-height: 23px;
        text-transform: uppercase;
        margin: 0 0 0 7px;
    }
    .close {
        position: relative;
        float: right;
        width: 23px;
        height: 23px;
        &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            background: #ffffff;
            top: 0;
            right: 50%;
            transform: rotate(-45deg);
            transition: all .3s ease;
        }
        &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            background: #ffffff;
            top: 0;
            right: 50%;
            transform: rotate(45deg);
            transition: all .3s ease;
        }
        &:hover {
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(135deg);
            }
        }
    }
    &.success {
        background-color: rgba(113,191,69,0.78);
    }
    &.error {
        background-color: rgba(238,31,26,0.78);
    }
    &.warning {
        background-color: rgba(238,114,23,0.78);
    }
}
.swalCancelBtn {
    background: transparent !important;
    color: var(--light-color) !important;
}
